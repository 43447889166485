// @mui icons
import InstagramIcon from "@mui/icons-material/Instagram";

export default {
  socials: [
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/cottageonthenew/",
    },
  ],
  menus: [
    {
      name: "More Info",
      items: [
        { name: "AirBnB", href: "https://airbnb.com/h/cottageonthenew" },
        { name: "Reviews", href: "https://www.airbnb.com/rooms/640684102271645049/reviews" },
        { name: "Pics", href: "https://airbnb.com/h/cottageonthenew?&modal=PHOTO_TOUR_SCROLLABLE" },
      ],
    },

  ],
};
