/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";


// Material Kit 2 React examples
import Navbar from "layouts/navbar";
import Header from "layouts/Header"
import Footer from "pages/Home/Footer";

// Presentation page components
import Activity from "layouts/Activities";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/custom/COTN_sunset.jpg"
import bgImage1 from "assets/images/custom/COTN_outlook.jpg"

// CSS
import '../pages.css'

function Home() {
  return (
    <>
    <Navbar
        routes={routes}
        action={{
          type: "external",
          route: "https://airbnb.com/h/cottageonthenew",
          label: "Book Now",
          color: "success",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "flex-start",
        }}
      >
       
       <Container   >  
        <Header content={"Contact The Owners"} />
      </Container>
        
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -3,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container sx={{ mt: 6 }}>
          <Activity
            title={"Contact Us"}  
            content={[`We are here to answer any questions you may have. Please do not hesitate to reach out via one of the 
            following methods`,
            <br />,<br />,
            `Email: info@cottageonthenew.com`
            ]}
            image = {bgImage1}
            />
        </Container>
      
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <Footer content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
