/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Material Kit 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

function Header({ content }) {
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {

    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
     window.addEventListener("resize", displayMobileNavbar);

     // Call the displayMobileNavbar function to set the state with the initial value.
     displayMobileNavbar();
 
     // Remove event listener on cleanup
     return () => window.removeEventListener("resize", displayMobileNavbar);

  }, []);

  return (
        <Grid container item xs={12} lg={7} justifyContent="center" mx="auto" style={{ marginTop: mobileView ? "100px" : "0px" }}>
        <MKTypography
            variant="h1"
            color="white"
            mt={2}
            mb={1}
            sx={({ breakpoints, typography: { size } }) => ({
            [breakpoints.down("md")]: {
                fontSize: size["3xl"],
            },
            })}
        >
            Cottage On the New
        </MKTypography>
        {!mobileView && (
        <MKTypography
            variant="body1"
            color="white"
            textAlign="center"
            px={{ xs: 6, lg: 12 }}
            mt={1}
        >
            {content}
        </MKTypography>
        )}
        </Grid>
  );
}


export default Header;
