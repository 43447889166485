/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import Navbar from "layouts/navbar";


// Presentation page sections
import Reviews from "pages/Home/Reviews";
import Footer from "pages/Home/Footer";
import Header from "layouts/Header"

// Presentation page components
import Activity from "layouts/Activities";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/custom/COTN_Window.jpg"
import bgImage2 from "assets/images/custom/COTN_Guitar.jpg"
import bgImage3 from "assets/images/custom/COTN_paddle.jpg"
import bgImage4 from "assets/images/custom/COTN_campfire.jpg"


function Home() {
  
    
  return (
    <>
    <Navbar
        routes={routes}
        action={{
          type: "external",
          route: "https://airbnb.com/h/cottageonthenew",
          label: "Book Now",
          color: "success",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "flex-start",
        }}
      >
      <Container   >  
        <Header content={"\"Where Time Slows & Water Heals\""} />
      </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -3,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container sx={{ mt: 6 }}>
          <Activity
            title={"Time to Unwind"}  
            content={`At our peaceful cottage retreat, unwind in a hammock, enjoy leisurely fishing, and experience the thrill 
            of tubing down the river. As evening falls, gather around a campfire with family and friends, sharing stories and 
            creating lasting memories. Each moment is a chance to connect with nature and loved ones, making your stay a perfect 
            blend of relaxation and adventure.`}
            image = {bgImage2}
            />
        </Container>
        <Container sx={{ mt: 6 }}>
          <Activity
            title={"Lets go Fishing"}  
            content={`Embark on a fishing adventure like no other at our tranquil retreat in the heart of Virginia. 
            Here, you can immerse yourself in the thrill of catching some of the finest fish the state has to offer, 
            from the spirited smallmouth bass to the majestic muskellunge, all just a cast away from your own backyard. 
            With the soothing sounds of nature as your backdrop, every fishing expedition becomes a cherished memory, 
            weaving stories of triumph and excitement along the picturesque waters of Virginia's most bountiful fishing spots.`}
            image = {bgImage3}
            />
        </Container>
        <Container sx={{ mt: 6 }}>
          <Activity
            title={"Campfire Stories"}  
            content={`Gathering around a campfire at our secluded cottage retreat, friends and family find refuge in the 
            crackling warmth of the firelight. Amidst the tranquil surroundings, shared laughter and heartfelt conversations 
            weave a tapestry of cherished memories, turning each evening into a timeless tale of togetherness and joy.`}
            image = {bgImage4}
            />
        </Container>
        <Reviews />
      
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <Footer content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
