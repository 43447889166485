/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Footer({ content }) {
  const {  menus } = content;

  return (
    <MKBox component="footer" >
      <Container>
        <Grid container spacing={1}>
          {menus.map(({ name: title, items }) => (
            <Grid key={title} item xs={12} >
              <MKBox display="flex" justifyContent="center" alignItems="center" marginTop="-50px" >
                {items.map(({ name, route, href }) => (
                  <MKBox key={name} mr={2}>
                      {href ? (
                        <MKTypography
                          component="a"
                          href={href}
                          target="_blank"
                          rel="noreferrer"
                          variant="button"
                          fontWeight="regular"
                          textTransform="capitalize"
                        >
                          {name}
                        </MKTypography>
                      ) : (
                        <MKTypography
                          component={Link}
                          to={route}
                          variant="button"
                          fontWeight="regular"
                          textTransform="capitalize"
                        >
                          {name}
                        </MKTypography>
                      )}
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          ))}
        </Grid>

      </Container>
    </MKBox>
  );
}

// Typechecking props for the DefaultFooter
Footer.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default Footer;
