/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import Navbar from "layouts/navbar";


// Presentation page sections
import Footer from "pages/Home/Footer";
import Header from "layouts/Header"

// Presentation page components
import Activity from "layouts/Activities";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/custom/COTN_familyBig.jpg"
import bgImage1 from "assets/images/custom/COTN_Guitar.jpg"

// CSS
import '../pages.css'


function Home() {
  return (
    <>
    <Navbar
        routes={routes}
        action={{
          type: "external",
          route: "https://airbnb.com/h/cottageonthenew",
          label: "Book Now",
          color: "success",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "flex-start",
        }}
      >
       <Container   >  
        <Header content={"Meet the Owners"} />
      </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -3,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container sx={{ mt: 6 }}>
          <Activity
            title={"The Owners"}  
            content={[`We are thrilled to welcome you to our cherished retreat, a place overflowing with fond memories and joy. 
            Both natives of Blacksburg, we found our haven in this riverside home in 2017, where we started our family and 
            created precious moments with our eldest daughter.`,
            <br />,<br />,
            `Growing up just a stone's throw away, I (Grant) spent my summers 
            exploring the riverbanks, catching crawdads, smallmouths, and endless sunshine. With the arrival of our second daughter,  
            we decided to open our doors to others, hoping to share the same warmth and coziness that this space has given us.`,
            <br />,<br />,
            `As you 
            make memories here, we kindly invite you to share your experiences in our guestbook, allowing us to live vicariously 
            through your adventures when we are unable to be here ourselves. Your presence adds to the tapestry of memories that make 
            this place our 'happy place.'`]}
            image = {bgImage1}
            />
        </Container>
      
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <Footer content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
