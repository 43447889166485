/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import Navbar from "layouts/navbar";

// Presentation page sections
import Footer from "pages/Home/Footer";
import Header from "layouts/Header"

// Presentation page components
import Activity from "layouts/Activities";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/custom/COTN_house2.jpg"
import bgImage1 from "assets/images/custom/COTN_Guitar.jpg"

// CSS
import '../pages.css'

function Home() {
  return (
    <>
    <Navbar
        routes={routes}
        action={{
          type: "external",
          route: "https://airbnb.com/h/cottageonthenew",
          label: "Book Now",
          color: "success",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "flex-start",
        }}
      >
       
      <Container   >  
        <Header content={["About the Cottage"]} />
      </Container>
        
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -3,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Container sx={{ mt: 6 }}>
          <Activity
            title={"Our Cottage"}  
            content={[`Nesteled in the heart of the New River Valley is the "Cottage on the New". 
            You will find yourself surrounded by nature with the New River flowing through the back yard!`,
            <br />,<br />,
            `The home brings all the feels of being in a secluded cottage but only a 25 minute drive to Virginia Tech
            and Radford University. Sit by the river and enjoy the smiles from the tubers floating from "The Junction", 
            Play a round of croquet, grill out, try your luck at catching a record small mouth bass, or simply lay in 
            the hammock and listen to the nature that surrounds.`,
            <br />,<br />,
            `When the night approaches you might find your friends and family telling stories around the campfire! 
            While you might not plan to leave this oasis during your stay you can take the back roads that follow 
            the river to an amazing quaint restruant called "The Palisades".`,
            <br />,<br />,
            `This is a very special place to me and my family. So special that it's only worth sharing!`]}
            image = {bgImage1}
            />
        </Container>
      
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <Footer content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
