/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import ReviewCard from "layouts/ReviewCard";

// Images


function Reviews() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={7}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2">Commited to our Guest</MKTypography>

          <MKTypography variant="body1" color="text" mb={2}>
          We are committed to ensuring you and your family create unforgettable memories at Cottage On The New, going above and beyond to make your stay truly special!
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <ReviewCard
              color="info"
              name="Greg"
              date="Oct 13 – 15, 2023"
              review="Amazing place to stay for a VT game. we had an amazing time"
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ReviewCard
              color="secondary"
              name="Maureen"
              date="Oct 7 – 9, 2023"
              review="We had a great time at The Cottage! Our family of five included two college students and a high school sophomore. The house was big enough overall but it is better suited for a family with young children. We enjoyed the fire pit by the river and the trains that passed by. The..."
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ReviewCard
              color="info"
              name="Candace"
              date="Sep 15 – 18, 2023"
              review="This was my family's second time staying at this cottage. The home offers activities for adults and children, a real music lover's delight. This time the weather was perfect for sitting outside and watching people tube down the river. my son fished from the back yard almost all..."
              rating={5}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
      </Container>
    </MKBox>
  );
}

export default Reviews;
